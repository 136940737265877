import axios from "axios";
import { getCurrentUserToken } from "utils";

class RentalsProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/rentals`,
  });

  pdfMonkeyClient = axios.create({
    baseURL: "https://api.pdfmonkey.io/api/v1",
  });

  getRentals(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getRental(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateRental(id, rentalParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, rentalParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createRental(rentalParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", rentalParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  generateContract(data) {
    const token = process.env.REACT_APP_PDFMONKEY_API_KEY;
    return this.pdfMonkeyClient.post("/documents", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getContract(contractId) {
    const token = process.env.REACT_APP_PDFMONKEY_API_KEY;
    return this.pdfMonkeyClient.get(`/document_cards/${contractId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getRentalInvoices(rentalId, page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${rentalId}/invoices`, {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteRental(rentalId) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${rentalId}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  exportRentals(query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/export", {
      params: { q: JSON.stringify(query) },
      responseType: "blob",
      headers: { "X-USER-TOKEN": token, "Content-Type": "application/json" },
    });
  }
}

const rentalsProviderInstance = new RentalsProvider();
export default rentalsProviderInstance;
