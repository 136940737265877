// React imports
import { useCallback, useState } from "react";

// External Libraries
import { Link, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKButton from "components/MKButton";
import Toolbar from "components/Toolbar";

// Providers
import rentalsProvider from "providers/rentalsProvider";

// Custom Components
import InvoicesTable from "pages/Rentals/invoicesTable";

function RentalInvoicesPage() {
  // Context and hooks
  const { id } = useParams();

  // Loading and error states
  const [loading, setLoading] = useState(true);

  // General states
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { data: rentalsData, total },
      } = await rentalsProvider.getRentalInvoices(id, page + 1, query);
      setLoading(false);
      return { invoices: rentalsData, total };
    },
    [id, query, page]
  );

  return (
    <MainLayout title="Cobros">
      <Toolbar
        setQuery={(query) => {
          setQuery(query);
          setPage(0);
        }}
        ransackQuery="customer_first_name_or_customer_last_name_or_unit_name_or_status_cont"
        newItemButton={
          <MKButton
            color="primary"
            size="small"
            variant="contained"
            component={Link}
            to="/invoices/new"
          >
            + Agregar Cobro
          </MKButton>
        }
      />

      <InvoicesTable
        fetchData={fetchData}
        loading={loading}
        page={page}
        setPage={setPage}
      />
    </MainLayout>
  );
}

export default RentalInvoicesPage;
