import React, { useCallback } from "react";
// React Router imports
import { useNavigate } from "react-router-dom";
import colors from "assets/theme/base/colors";

//importar componentes de react-flow
import { NodeToolbar, Position } from "reactflow";

//importar componentes de Material-UI
import LocationOffIcon from "@mui/icons-material/LocationOff";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RentIcon from "@mui/icons-material/House";
import EvictIcon from "@mui/icons-material/Block";
import LockIcon from "@mui/icons-material/Lock";
import UnlockIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";
import Autorenew from "@mui/icons-material/Autorenew";
import UpdateIcon from "@mui/icons-material/Update";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

// Importar la función de alerta personalizada
import MKBox from "components/MKBox";
import customAlert from "components/Alerts/CustomAlert";

// Importar proveedores y hooks de navegación
import unitsProvider from "providers/unitsProvider";
import rentalsProvider from "providers/rentalsProvider";
import customersProvider from "providers/customersProvider";

// Utilities
import { longDateFormat } from "utils";

const UnitNode = ({ data, isSelected }) => {
  const {
    label,
    status,
    width,
    height,
    active_rental,
    unit_id: id,
    is_irregular,
    svgPoints = "",
  } = data;

  // TO-DO si el contrato está atrasado poner color rentalStatus.atrasado
  const statusColor = colors.unitStatus[status] || "#000";
  // Context and hooks
  const navigate = useNavigate();

  // Función para editar la unidad
  const handleEdit = useCallback(() => {
    navigate(`/units/${id}/edit`);
  }, [id, navigate]);

  // Función para rentar la unidad
  const handleRent = useCallback(() => {
    navigate(`/rentals/new`, { state: { unitId: id, fromMap: true } });
  }, [id, navigate]);

  // Función para eliminar la unidad
  const handleDelete = useCallback(() => {
    customAlert({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede deshacer. Se perderá toda la información de la unidad.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await unitsProvider.deleteUnit(id); // Eliminar de la base de datos
          customAlert({
            title: "Eliminado!",
            text: "La unidad ha sido eliminada.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          }).then(() => {
            navigate(0); // Recarga la página cuando se eliminó una unidad
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Ocurrió un error al intentar eliminar la unidad.",
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      }
    });
  }, [id, navigate]);

  // Función para quitar la unidad del mapa
  const handleUnmapUnit = useCallback(async () => {
    customAlert({
      title: "¿Quitar unidad del mapa?",
      text: "Esta acción no elimina la unidad, pero la quitará del mapa actual.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, quitar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await unitsProvider.updateUnit(id, { is_mapped: false });
          customAlert({
            title: "Unidad quitada del mapa",
            text: "La unidad ya no está visible en el mapa.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          }).then(() => {
            navigate(0);
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Ocurrió un problema al quitar la unidad del mapa.",
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      }
    });
  }, [id, navigate]);

  // Función para bloquear/desbloquear la unidad
  const handleBlockChange = useCallback(async () => {
    const newStatus = status === "bloqueada" ? "disponible" : "bloqueada";
    await unitsProvider.updateUnit(id, { status: newStatus });
  }, [id, status]);

  // Función para desalojar la unidad
  const handleEvict = useCallback(async () => {
    // Si el contrato está terminado la unidad se puede desalojar sin motivo de cancelación
    if (active_rental.status === "terminado") {
      customAlert({
        title: "¿Estás seguro?",
        text: "La unidad será desalojada",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Desalojar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await unitsProvider.updateUnit(active_rental.unit_id, {
              status: "disponible",
            });

            customAlert({
              title: "Unidad desalojada!",
              text: "La unidad ha sido desalojada exitosamente.",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al desalojar unidad",
              icon: "error",
            });
          }
        }
      });
    } else {
      // Si el contrato está activo o atrasado, se solicita un motivo de cancelación
      customAlert({
        title: "¿Estás seguro?",
        text: "Escribe el motivo de desalojo y cancelación de contrato",
        input: "text",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Desalojar",
        cancelButtonText: "Mantener contrato",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.updateRental(active_rental.id, {
              status: "cancelado",
              motive: result.value,
            });

            customAlert({
              title: "Unidad desalojada!",
              text: "La unidad ha sido desalojada exitosamente.",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al cancelar el contrato.",
              icon: "error",
            });
          }
        }
      });
    }
  }, [active_rental]);

  const handleExtendContract = useCallback(() => {
    customAlert({
      title: "Extender contrato",
      text: "Selecciona la nueva fecha de finalización del contrato",
      input: "date",
      inputValue: active_rental.end_date,
      inputAttributes: {
        min: new Date(active_rental.end_date).toISOString().split("T")[0],
      },
      showCancelButton: true,
      confirmButtonText: "Extender",
      cancelButtonText: "Cancelar",
      inputValidator: (value) => {
        if (!value) {
          return "Debes seleccionar una nueva fecha";
        }
        if (new Date(value) <= new Date(active_rental.end_date)) {
          return "La nueva fecha debe ser mayor a la fecha de finalización actual";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await rentalsProvider.updateRental(active_rental.id, {
            end_date: result.value,
          });

          customAlert({
            title: "Contrato extendido",
            html: `
              <p>La nueva fecha de finalización es:</p>
              <p style="font-weight: bold;">
              ${longDateFormat(result.value)}</p>
            `,
            icon: "success",
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Hubo un problema al extender el contrato.",
            icon: "error",
          });
        }
      }
    });
  }, [active_rental]);

  const handleRenewContract = useCallback(() => {
    try {
      if (active_rental) {
        if (active_rental.status === "atrasado") {
          customAlert({
            title: "Aviso importante",
            html: `
            <p>Este contrato tiene cobros atrasados.</p>
            <p>No se modificarán ni eliminarán, y seguirán registrándose como pendientes.</p> `,
            icon: "warning",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/rentals/new", {
                state: {
                  rentalData: active_rental,
                  isRenewing: true,
                  fromMap: true,
                },
              });
            }
          });
        }
      }
    } catch (error) {
      console.error("Error al renovar el contrato:", error);
    }
  }, [navigate, active_rental]);

  const handleChangeUnit = useCallback(() => {
    const changeUnit = async () => {
      try {
        const { data } = await customersProvider.getCustomer(
          active_rental.user_id
        );

        console.log("Customer data:", data);
        const customerData = {
          user_id: active_rental.user_id,
          first_name: data.first_name,
          last_name: data.last_name,
          user_guarantee_name: active_rental.user_guarantee_name || "",
          stored_goods: active_rental.stored_goods || "",
          previous_rental: active_rental.id,
        };

        if (active_rental.status === "atrasado") {
          customAlert({
            title: "Aviso importante",
            html: `
            <p>Este contrato tiene cobros atrasados.</p>
            <p>No se modificarán ni eliminarán, y seguirán registrándose como pendientes.</p>`,
            icon: "warning",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/+/new", {
                state: { customerData, isUnitChanged: true, fromMap: true },
              });
            }
          });
        }
      } catch (error) {
        console.error("Error al cambiar de unidad:", error);
      }
    };

    changeUnit();
  }, [navigate, active_rental]);

  const svgStyles = {
    fill: statusColor, // Color dinámico según el estado
    transition: "fill 0.3s ease", // Transición suave para cambios de estado
    margin: 0,
  };

  // Calcular el ancho y alto máximo de los puntos del SVG
  let maxX = 0;
  let maxY = 0;
  let scaledPoints = svgPoints;

  if (is_irregular && svgPoints) {
    const points = svgPoints.split(" ").map((point) => point.split(","));
    points.forEach(([x, y]) => {
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    });

    // Escalar los puntos del SVG según el ancho y alto del contenedor
    const scaleX = width / maxX;
    const scaleY = height / maxY;
    scaledPoints = svgPoints
      .split(" ")
      .map((point) => {
        const [x, y] = point.split(",").map(Number);
        return `${x * scaleX},${y * scaleY}`;
      })
      .join(" ");
  }

  return (
    <div
      style={{
        padding: is_irregular ? 0 : 10,
        width: width || 200,
        height: height || 200,
        borderRadius: 5,
        backgroundColor: is_irregular ? "transparent" : statusColor,
        cursor: "grab",
        opacity: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `${Math.min(width, height) / 3}px`,
        clipPath:
          is_irregular && svgPoints
            ? `polygon(${scaledPoints})` // Forma basada en los puntos del SVG
            : "none", // Forma estándar (rectángulo)
      }}
    >
      {is_irregular && (
        <svg
          viewBox={`0 0 ${width} ${height}`} // Vista del SVG para el contenedor
          xmlns="http://www.w3.org/2000/svg"
          style={{
            ...svgStyles,
            width: "100%", // Ajustar el ancho del SVG al contenedor
            height: "100%", // Ajustar la altura del SVG al contenedor
            objectFit: "contain", // Contener la figura dentro del contenedor
          }}
          preserveAspectRatio="xMidYMid meet" // Escalado proporcional al contenedor
        >
          <polygon points={scaledPoints} />
        </svg>
      )}

      <MKBox
        position="absolute"
        color="white"
        sx={{
          backgroundColor: "rgba(173, 172, 172, 0.39)",
          padding: "4px 8px",
          borderRadius: "4px",
          zIndex: 10,
        }}
      >
        <strong>{label}</strong>
      </MKBox>

      <NodeToolbar
        isVisible={isSelected}
        position={Position.Top}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: 4,
          padding: "4px",
        }}
      >
        <Tooltip title="Editar" arrow>
          <IconButton
            size="small"
            color="default"
            onClick={handleEdit}
            sx={{
              "&:hover > svg": {
                color: "info.main",
              },
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        {status === "disponible" && (
          <Tooltip title="Rentar" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleRent}
              sx={{
                "&:hover > svg": {
                  color: "success.main",
                },
              }}
            >
              <RentIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Extender contrato" arrow>
          <IconButton
            size="small"
            color="default"
            onClick={handleExtendContract}
            sx={{
              "&:hover > svg": {
                color: "info.main",
              },
            }}
          >
            <UpdateIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Renovar contrato" arrow>
          <IconButton
            size="small"
            color="default"
            onClick={handleRenewContract}
            sx={{
              "&:hover > svg": {
                color: "success.main",
              },
            }}
          >
            <Autorenew />
          </IconButton>
        </Tooltip>

        <Tooltip title="Cambiar de Unidad" arrow>
          <IconButton
            size="small"
            color="default"
            onClick={handleChangeUnit}
            sx={{
              "&:hover > svg": {
                color: "info.main",
              },
            }}
          >
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>

        {status === "bloqueada" ? (
          <Tooltip title="Desbloquear" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleBlockChange}
              sx={{
                "&:hover > svg": {
                  color: "success.main",
                },
              }}
            >
              <UnlockIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Bloquear" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleBlockChange}
              sx={{
                "&:hover > svg": {
                  color: "error.main",
                },
              }}
            >
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}

        {(status === "rentada" || status === "atrasada") && (
          <Tooltip title="Desalojar" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleEvict}
              sx={{
                "&:hover > svg": {
                  color: "warning.main",
                },
              }}
            >
              <EvictIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Quitar del mapa" arrow>
          <IconButton
            size="small"
            color="default"
            onClick={handleUnmapUnit}
            sx={{
              "&:hover > svg": {
                color: "warning.main",
              },
            }}
          >
            <LocationOffIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Eliminar" arrow>
          <IconButton
            size="small"
            color="default"
            onClick={handleDelete}
            sx={{
              "&:hover > svg": {
                color: "error.main",
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </NodeToolbar>
    </div>
  );
};

export default React.memo(UnitNode);
