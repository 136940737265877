// React imports
import { useState } from "react";

// React Router imports
import { useNavigate } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material UI Components
import Card from "@mui/material/Card";

// Layouts
import AuthLayout from "layouts/authLayout";

// Custom Alert
import customAlert from "components/Alerts/CustomAlert";

// Custom Colors
import colors from "assets/theme/base/colors";

// Providers
import authProvider from "providers/authProvider";

function PasswordRecovery() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authProvider.requestPasswordReset(email);

      // Mostrar alerta personalizada
      await customAlert({
        title: "Correo enviado",
        text: "El correo de recuperación fue enviado con éxito.",
        icon: "success",
        confirmButtonText: "Aceptar",
      });

      // Redirigir al inicio de sesión
      navigate("/sign-in");
    } catch (error) {
      console.error("Error al enviar el correo de recuperación:", error);

      // Mostrar alerta de error
      await customAlert({
        title: "Error",
        text: "Hubo un problema al enviar el correo de recuperación. Por favor, inténtalo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <AuthLayout>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info.main"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          pt={2.5}
          pb={2.875}
          px={2.5}
          textAlign="center"
          lineHeight={1}
        >
          <MKTypography
            variant="h4"
            fontWeight="medium"
            color={colors.white}
            mt={1}
          >
            Recupera tu contraseña
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox
            component="form"
            role="form"
            method="post"
            onSubmit={handleSubmit}
          >
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Ingresa el email asociado a tu cuenta"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton
                type="submit"
                variant="gradient"
                color="primary"
                fullWidth
              >
                Recuperar Contraseña
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </AuthLayout>
  );
}

export default PasswordRecovery;
