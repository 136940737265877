// React imports
import { useCallback, useContext, useMemo, useState } from "react";

// External Libraries
import { Link, useLocation } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import Toolbar from "components/Toolbar";
import customAlert from "components/Alerts/CustomAlert";

// Material UI Components
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";

// Providers
import rentalsProvider from "providers/rentalsProvider";

// Custom Components
import RentalsTable from "pages/Rentals/table";

// Contexts
import SessionContext from "contexts/SessionContext";

// Utilities
import { shortDateFormat } from "utils";

function RentalsPage() {
  // Context and hooks
  const location = useLocation();
  const { currentUser } = useContext(SessionContext);

  // Derived states or memoized values
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}`] = value;
      return acc;
    }, {});
  }, [location.search]);

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // General states
  const [query, setQuery] = useState(searchParams);
  const [page, setPage] = useState(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const {
        data: { data: rentalsData, total },
      } = await rentalsProvider.getRentals(page + 1, query);
      return { rentals: rentalsData, total };
    } catch (error) {
      console.log(error);
      let errorMsg =
        "Hubo un problema al cargar los datos de las rentas. Por favor, intenta de nuevo.";
      if (error.response && error.response.data) {
        errorMsg = Array.isArray(error.response.data)
          ? error.response.data.join(", ")
          : error.response.data;
      }
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  }, [query, page]);

  const exportRentals = useCallback(async () => {
    try {
      setLoading(true);
      const { data: blobData } = await rentalsProvider.exportRentals(query);
      const url = window.URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `rentas-${shortDateFormat(new Date())}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      customAlert({
        title: "Error",
        text: "Hubo un problema al exportar las rentas.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [query]);

  return (
    <MainLayout title="Contratos">
      <Toolbar
        setQuery={(query) => {
          setQuery(query);
          setPage(0);
        }}
        ransackQuery="unit_name_or_user_last_name_or_user_first_name_or_status_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              color="primary"
              size="small"
              variant="contained"
              component={Link}
              to="/rentals/new"
            >
              + Agregar Contrato
            </MKButton>
            {[1, 2].includes(currentUser.role_id) && (
              <Tooltip title="Exportar a Excel">
                <MKButton iconOnly onClick={exportRentals}>
                  <DownloadIcon />
                </MKButton>
              </Tooltip>
            )}
          </Box>
        }
      />

      {errorMessage && (
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "15vh" }}
        >
          <MKAlert color="error" dismissible={true}>
            {errorMessage}
          </MKAlert>
        </Grid>
      )}
      <RentalsTable
        fetchData={fetchData}
        loading={loading}
        page={page}
        setPage={setPage}
      />
    </MainLayout>
  );
}

export default RentalsPage;
