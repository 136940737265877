// React imports
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// React Router imports
import { Link, useLocation, useNavigate } from "react-router-dom";

// Material UI components
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import PrintIcon from "@mui/icons-material/Print";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TextField from "@mui/material/TextField";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Custom components
import ActionsMenu from "components/Menu";
import customAlert from "components/Alerts/CustomAlert";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import paymentsProvider from "providers/paymentsProvider";
import {
  currencyFormatter,
  PAYMENT_FORMS,
  PAYMENT_STATUS,
  shortDateFormat,
  longDateFormat,
  PAYMENT_TYPES,
} from "utils";

//import sweetalert2
import { useReactToPrint } from "react-to-print";

// Contexts
import SessionContext from "contexts/SessionContext";

const statusOptions = PAYMENT_STATUS.map((status) => ({
  value: status,
  label: status,
}));

function ProductsTable({ permanentQuery, query }) {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);
  const location = useLocation();
  const navigate = useNavigate();
  const contentToPrint = useRef(null); // Ref to handle the content to be printed

  // Derived states or memoized values
  const columns = useMemo(
    () => [
      { name: "fecha de pago", align: "left" },
      { name: "cliente", align: "left" },
      { name: "productos", align: "left" },
      { name: "total", align: "left" },
      { name: "forma de pago", align: "left" },
      { name: "concepto", align: "left" },
      { name: "estatus", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(true);

  // General states
  const [state, setState] = useState({
    payments: [], // List of products
    rows: [],
    total: 0,
    page: 0,
  });
  const [printingPayment, setPrintingPayment] = useState(null);
  const [subsidiaryData, setSubsidiaryData] = useState(null);

  // Destructure the state from the location object
  const { state: locationState } = location;

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, page) => {
    setState((prevState) => ({ ...prevState, page }));
  }, []);

  // Handles print event, preparing and resetting the content to be printed
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    onAfterPrint: () => {
      setPrintingPayment(null);
      navigate("/payments");
    },
  });

  // Fetches payment data from the provider
  // Updates the state with the processed data and handles loading and error states.
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: paymentsData, total },
      } = await paymentsProvider.getPayments(state.page + 1, {
        ...query,
        ...permanentQuery,
        payment_type_matches: PAYMENT_TYPES.product,
      });
      setState((prevState) => ({
        ...prevState,
        payments: paymentsData || [],
        total: total || 0,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        payments: [],
        total: 0,
      }));
    } finally {
      setLoading(false);
    }
  }, [state.page, query, permanentQuery]);

  // Deletes a specific payment after user confirmation and refreshes the payment list.
  const deletePayment = useCallback(
    async function (paymentId) {
      customAlert({
        title: "¿Estás seguro?",
        text: "No podrás revertir esta acción. Se perderá toda la información del pago.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await paymentsProvider.deletePayment(paymentId);
            await fetchData(state.page);
            navigate("/payments");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al eliminar el pago.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData, state.page, navigate]
  );

  // Updates the status of a specific payment and refreshes the payment list.
  const updatePaymentStatus = useCallback(
    async function (paymentId, newStatus) {
      await paymentsProvider.updatePayment(paymentId, {
        payment: { status: newStatus },
      });
      fetchData();
    },
    [fetchData]
  );

  useEffect(() => {
    setState((prevState) => ({ ...prevState, page: 0 }));
  }, [query, permanentQuery]);

  //Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Sets the printing payment data if a new payment is passed through location state (e.g., via navigation).
  useEffect(() => {
    if (locationState && locationState.newPayment) {
      const payment = JSON.parse(locationState.newPayment);
      setPrintingPayment(payment);
    }
  }, [locationState]);

  // Updates subsidiary data based on the current user's subsidiary, if available.
  useEffect(() => {
    if (currentUser && currentUser.subsidiary) {
      setSubsidiaryData(currentUser.subsidiary);
    }
  }, [currentUser]);

  // Initiates the printing process once both the payment and invoice data are available.
  useEffect(() => {
    if (printingPayment) {
      handlePrint();
    }
  }, [printingPayment, handlePrint]);

  // Updates table rows based on payment data, mapping details and actions for each payment.
  useEffect(() => {
    if (state.payments && state.payments.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.payments.map((payment) => ({
          "fecha de pago": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(payment.date)}
            </MKTypography>
          ),
          cliente: (
            <MKButton
              variant="text"
              color="dark"
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
              component={Link}
              to={`/customers/${payment.customer?.id}#Pagos`}
            >
              {`${payment.customer?.first_name} ${payment.customer?.last_name}`}
            </MKButton>
          ),
          productos: (
            <MKTypography variant="button" fontWeight="medium">
              {payment.payments_products && payment.payments_products.length > 0
                ? payment.payments_products?.map((productPayment, index) => (
                    <span key={index}>
                      {productPayment.product.name} - {productPayment.quantity}{" "}
                      pz
                      <br />
                    </span>
                  ))
                : `${payment.payable.name} - ${payment.quantity}`}
            </MKTypography>
          ),
          total: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(payment.amount)}
            </MKTypography>
          ),
          "forma de pago": (
            <MKTypography variant="button" fontWeight="medium">
              {payment.payment_form
                ? PAYMENT_FORMS[payment.payment_form]
                : payment.method}
            </MKTypography>
          ),
          concepto: (
            <MKTypography variant="button" fontWeight="medium">
              {payment.concept}
            </MKTypography>
          ),
          estatus: (
            <Autocomplete
              options={statusOptions}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value.toLowerCase() === value.value.toLowerCase()
              }
              value={
                statusOptions.find(
                  (status) =>
                    status.value.toLowerCase() === payment.status.toLowerCase()
                ) || null
              }
              onChange={(_event, newValue) => {
                if (newValue) {
                  updatePaymentStatus(payment.id, newValue.value);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    text: "Imprimir",
                    icon: <PrintIcon />,
                    onClick: () => {
                      setPrintingPayment(payment);
                    },
                  },
                  !payment.invoiced && {
                    text: "Generar factura",
                    icon: <ReceiptIcon />,
                    onClick: () =>
                      navigate(`/Payments/${payment.id}/create-invoice`),
                    color: "blue",
                    disabled: true,
                  },
                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () => deletePayment(payment.id),
                  },
                ].filter(Boolean)}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [state.payments, deletePayment, updatePaymentStatus, navigate]);

  return (
    <>
      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron pagos de productos
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>

      <div style={{ display: "none" }}>
        <div ref={contentToPrint}>
          <div
            style={{
              width: "auto",
              height: "auto",
              padding: "20px",
              margin: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="Logo Usted Guarda"
                width="90"
                style={{ position: "absolute", left: "40px" }}
              />
              <p
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                RECIBO DE PAGO DE PRODUCTO
              </p>
              <p style={{ position: "absolute", right: "40px", margin: 0 }}>
                <span style={{ color: "black", fontWeight: "normal" }}>
                  No.
                </span>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  {String(printingPayment?.id).padStart(4, "0")}{" "}
                </span>
              </p>
            </div>
            <div
              style={{
                border: "2px solid #FF4713",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <table
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "0px 8px",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                      colSpan="2"
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "20px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Sucursal de {subsidiaryData?.name}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 8px" }} colSpan="2">
                      <p>
                        Razon social:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {subsidiaryData?.fiscal_name}
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 8px" }} colSpan="2">
                      <p>
                        R.F.C:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {subsidiaryData?.fiscal_number}
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 8px" }} colSpan="2">
                      <p>
                        Dirección:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {subsidiaryData?.street},{" "}
                          {subsidiaryData?.exterior_number},{" "}
                          {subsidiaryData?.interior_number
                            ? `${subsidiaryData?.interior_number}, `
                            : ""}
                          {subsidiaryData?.neighborhood},{" "}
                          {subsidiaryData?.zip_code}, {subsidiaryData?.city},{" "}
                          {subsidiaryData?.state}
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{
                border: "2px solid #FF4713",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "16px",
              }}
            >
              <table
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "0px 8px" }} colSpan="2">
                      <p style={{ fontWeight: "normal" }}>
                        Estatus del pago:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {printingPayment?.status}
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 8px" }} colSpan="2">
                      <p style={{ fontWeight: "normal" }}>
                        Fecha de pago:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {longDateFormat(printingPayment?.date)}
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{
                border: "2px solid #FF4713",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "16px",
              }}
            >
              <table
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{ padding: "8px", textAlign: "center" }}
                      colSpan="2"
                    >
                      <p style={{ fontWeight: "normal" }}>
                        Por medio del presente, se hace constar que{" "}
                        <span style={{ fontWeight: "bold" }}>
                          C. {printingPayment?.customer?.first_name}{" "}
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {printingPayment?.customer?.last_name}
                        </span>
                        ,{" "}
                        {printingPayment?.customer?.fiscal_number && (
                          <>
                            con R.F.C.
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {printingPayment?.customer?.fiscal_number}
                            </span>
                            ,{" "}
                          </>
                        )}
                        ha realizado un pago por la cantidad de
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {currencyFormatter.format(
                            printingPayment?.amount
                          )}{" "}
                        </span>
                        {printingPayment?.concept && (
                          <>
                            correspondiente al concepto
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {printingPayment?.concept}{" "}
                            </span>
                          </>
                        )}
                        en relación a la compra de los siguientes productos:
                        <br />
                        {printingPayment?.payments_products &&
                        printingPayment?.payments_products.length > 0
                          ? printingPayment?.payments_products?.map(
                              (productPayment, index) => (
                                <span key={index}>
                                  {`${productPayment.product.name} - ${productPayment.quantity} pz`}
                                  <br />
                                </span>
                              )
                            )
                          : `${printingPayment?.payable.name} - ${printingPayment?.quantity} pz`}
                        <br />
                        Dicho pago ha sido efectuado mediante la forma
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {printingPayment?.payment_form
                            ? PAYMENT_FORMS[printingPayment?.payment_form]
                            : printingPayment?.method}
                        </span>{" "}
                        y se deja constancia de este documento como comprobante
                        de la operación realizada.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ padding: "12px", textAlign: "center" }}
                      colSpan="2"
                    >
                      <p style={{ fontWeight: "normal", margin: 0 }}>
                        {subsidiaryData?.city}, {subsidiaryData?.state},{" "}
                        {printingPayment?.date
                          ? shortDateFormat(printingPayment.date)
                          : ""}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "50px 8px 0px 8px",
                        textAlign: "center",
                        verticalAlign: "middle",
                        height: "100px",
                      }}
                    >
                      <hr
                        style={{
                          width: "80%",
                          borderTop: "1px solid black",
                          margin: "20px auto 5px auto",
                        }}
                      />
                      <p style={{ fontWeight: "normal" }}>Firma del receptor</p>
                    </td>
                    <td
                      style={{
                        padding: "50px 8px 0px 8px",
                        textAlign: "center",
                        verticalAlign: "middle",
                        height: "100px",
                      }}
                    >
                      <hr
                        style={{
                          width: "80%",
                          borderTop: "1px solid black",
                          margin: "20px auto 5px auto",
                        }}
                      />
                      <p style={{ fontWeight: "normal" }}>Firma</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        verticalAlign: "middle",
                        height: "auto",
                      }}
                      colSpan="2"
                    >
                      <p
                        style={{
                          fontWeight: "normal",
                          paddingTop: "20px",
                          fontSize: "16px",
                        }}
                      >
                        Este documento{" "}
                        <span style={{ fontWeight: "bold" }}>
                          no es un complemento fiscal
                        </span>
                        . El arrendatario es responsable de obtener la factura
                        correspondiente en caso de requerirla.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsTable;
