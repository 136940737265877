// React imports
import { useCallback, useState } from "react";

// React Router imports
import { Link } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import Toolbar from "components/Toolbar";

// Providers
import rentalsProvider from "providers/rentalsProvider";

// Pages
import RentalsTable from "pages/Rentals/table";

function CustomerRentalsTab({ customer }) {
  // Loading and error states
  const [loading, setLoading] = useState(true);

  // General states
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { data: rentalsData, total },
      } = await rentalsProvider.getRentals(page + 1, {
        user_id_eq: customer.id,
        ...query,
      });
      setLoading(false);
      return { rentals: rentalsData, total };
    },
    [query, customer, page]
  );
  return (
    <MKBox>
      <Toolbar
        setQuery={(query) => {
          setQuery(query);
          setPage(0);
        }}
        ransackQuery="unit_name_or_status_cont"
        newItemButton={
          <MKBox mb={3}>
            {" "}
            <MKButton
              color="primary"
              size="small"
              variant="contained"
              component={Link}
              to="/rentals/new"
            >
              + Agregar Contrato
            </MKButton>
          </MKBox>
        }
      />
      <RentalsTable
        fetchData={fetchData}
        loading={loading}
        page={page}
        setPage={setPage}
      />
    </MKBox>
  );
}

export default CustomerRentalsTab;
