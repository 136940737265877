// React imports
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// External Libraries
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Toolbar from "components/Toolbar";

// Material UI Components
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";

// Custom Components
import ActionsMenu from "components/Menu";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// Providers
import expenseCategoriesProvider from "providers/expenseCategoriesProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

function ExpenseCategoriesPage() {
  // Context and hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(SessionContext);

  // Derived states or memoized values
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}`] = value;
      return acc;
    }, {});
  }, [location.search]);

  const columns = useMemo(
    () => [
      { name: "nombre", align: "left" },
      { name: "descripción", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // General states
  const [state, setState] = useState({
    expenseCategories: [],
    rows: [],
    total: 0,
    page: 0,
    query: searchParams,
  });

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, page) => {
    setState((prevState) => ({ ...prevState, page }));
  }, []);

  // Fetches expense categories data and updates the state with the list of expense categories and total count
  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const {
        data: { data: expenseCategoriesData, total },
      } = await expenseCategoriesProvider.getExpenseCategories(
        state.page + 1,
        state.query
      );
      setState((prevState) => ({
        ...prevState,
        expenseCategories: expenseCategoriesData,
        total,
      }));
    } catch (error) {
      console.error("Error fetching expense categories data", error);
      let errorMsg =
        "Hubo un problema al cargar las categorías de gastos. Por favor, intenta de nuevo.";
      if (error.response && error.response.data) {
        errorMsg = Array.isArray(error.response.data)
          ? error.response.data.join(", ")
          : error.response.data;
      }
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  }, [state.page, state.query]);

  // Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreateExpenseCategory = useCallback(() => {
    customAlert({
      title: "Crear nueva categoría",
      html:
        '<div style="margin-bottom: 5px;">' +
        '<input id="category_name" class="swal2-input" placeholder="Nombre" style="width: 60%; padding: 10px; font-size: 16px; font-family: inherit; box-sizing: border-box; line-height: 1.5;">' +
        "</div>" +
        "<div>" +
        '<textarea id="category_description" class="swal2-textarea" placeholder="Descripción" style="width: 60%; padding: 10px; font-size: 16px; font-family: inherit; box-sizing: border-box; line-height: 1.5;"></textarea>' +
        "</div>",
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Crear",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const category_name = document.getElementById("category_name").value;
        const category_description = document.getElementById(
          "category_description"
        ).value;

        if (!category_name || category_name.length < 1) {
          return Swal.showValidationMessage(
            "Se requiere un nombre para la categoría"
          );
        }
        if (!category_description || category_description.length < 1) {
          return Swal.showValidationMessage(
            "Se requiere una descripción para la categoría"
          );
        }

        return { category_name, category_description };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { category_name, category_description } = result.value;
        const subsidiaryId = currentUser.subsidiary.id;

        setLoading(true);
        try {
          const newCategory =
            await expenseCategoriesProvider.createExpenseCategory({
              name: category_name,
              description: category_description,
              subsidiary_id: subsidiaryId,
            });

          setState((prevState) => ({
            ...prevState,
            expenseCategories: [
              ...prevState.expenseCategories,
              newCategory.data,
            ],
            total: prevState.total + 1,
          }));

          customAlert({
            title: "Categoría creada",
            text: `Se ha creado la categoría: ${category_name}`,
            icon: "success",
          });
        } catch (error) {
          const errorMessage =
            error.response?.data?.[0] ||
            error.message ||
            "Hubo un problema al crear la categoría.";
          customAlert({
            title: "Error",
            text: errorMessage,
            icon: "error",
          });
        } finally {
          setLoading(false);
        }
      }
    });
  }, [currentUser, setState, setLoading]);

  const handleEditExpenseCategory = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data: category } =
        await expenseCategoriesProvider.getExpenseCategory(id);

      customAlert({
        title: "Editar categoría",
        html:
          `<div style="margin-bottom: 5px;">` +
          `<input id="category_name" class="swal2-input" placeholder="Nombre" value="${category.name}" style="width: 60%; padding: 10px; font-size: 16px; font-family: inherit; line-height: 1.5; box-sizing: border-box;">` +
          `</div>` +
          `<div>` +
          `<textarea id="category_description" class="swal2-textarea" placeholder="Descripción" style="width: 60%; padding: 10px; font-size: 16px; font-family: inherit; line-height: 1.5; box-sizing: border-box;">${category.description}</textarea>` +
          `</div>`,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          const category_name = document.getElementById("category_name").value;
          const category_description = document.getElementById(
            "category_description"
          ).value;

          if (!category_name || category_name.length < 1) {
            return Swal.showValidationMessage(
              "Se requiere un nombre para la categoría"
            );
          }
          if (!category_description || category_description.length < 1) {
            return Swal.showValidationMessage(
              "Se requiere una descripción para la categoría"
            );
          }

          return { category_name, category_description };
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { category_name, category_description } = result.value;

          try {
            const updatedCategory =
              await expenseCategoriesProvider.updateExpenseCategory(id, {
                name: category_name,
                description: category_description,
              });

            setState((prevState) => {
              const updatedCategories = prevState.expenseCategories.map(
                (item) => (item.id === id ? updatedCategory.data : item)
              );
              return {
                ...prevState,
                expenseCategories: updatedCategories,
              };
            });

            customAlert({
              title: "Categoría actualizada",
              text: `Se ha actualizado la categoría: ${category_name}`,
              icon: "success",
            });
          } catch (error) {
            const errorMessage =
              error.response?.data?.[0] ||
              error.message ||
              "Hubo un problema al actualizar la categoría.";
            customAlert({
              title: "Error",
              text: errorMessage,
              icon: "error",
            });
          }
        }
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.[0] ||
        error.message ||
        "No se pudo cargar la categoría.";
      customAlert({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteExpenseCategory = useCallback(
    (id) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer. Se perderá toda la información de la categoría.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await expenseCategoriesProvider.deleteExpenseCategory(id);
            fetchData();
            customAlert({
              title: "Eliminada!",
              text: "La categoría ha sido eliminada.",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
            navigate("/expenseCategories");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Ocurrió un error al intentar eliminar la categoría.",
              icon: "error",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  useEffect(() => {
    if (state.expenseCategories && state.expenseCategories.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.expenseCategories.map((expenseCategories) => ({
          nombre: (
            <MKTypography variant="button" fontWeight="medium">
              {expenseCategories.name}
            </MKTypography>
          ),
          descripción: (
            <MKTypography variant="button" fontWeight="medium">
              {expenseCategories.description}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    text: "Editar",
                    icon: <EditIcon />,
                    onClick: () =>
                      handleEditExpenseCategory(expenseCategories.id),
                  },
                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () =>
                      handleDeleteExpenseCategory(expenseCategories.id),
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [
    state.expenseCategories,
    handleEditExpenseCategory,
    handleDeleteExpenseCategory,
  ]);

  return (
    <MainLayout title="Categorías de gastos">
      <Toolbar
        setQuery={(query) =>
          setState((prevState) => ({ ...prevState, query, page: 0 }))
        }
        ransackQuery="name_or_description_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              onClick={handleCreateExpenseCategory}
            >
              + Agregar categoría
            </MKButton>
          </Box>
        }
      />

      {errorMessage && (
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "15vh" }}
        >
          <MKAlert color="error" dismissible={true}>
            {errorMessage}
          </MKAlert>
        </Grid>
      )}

      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron categorias
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </MainLayout>
  );
}

export default ExpenseCategoriesPage;
