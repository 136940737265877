import axios from "axios";

class AuthProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/auth`,
  });

  login(loginData) {
    return this.axiosClient.post("/login", loginData);
  }

  requestPasswordReset(email) {
    return this.axiosClient.post("/request_password_reset", { email });
  }

  resetPassword(resetData) {
    return this.axiosClient.post("/reset_password", resetData);
  }
}

const authProviderInstance = new AuthProvider();
export default authProviderInstance;
