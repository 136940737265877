// Material UI components
import Icon from "@mui/material/Icon";

// Admin pages
import UsersPage from "pages/Users";
import SubsidiariesPage from "pages/Subsidiary";
import EditSubsidiaryPage from "pages/Subsidiary/editSubsidiary";

// Manager pages
import DashboardPage from "pages/Dashboard";
import CustomersPage from "pages/Customers";
import UnitsPage from "pages/Units";
import RentalsPage from "pages/Rentals";
import InvoicesPage from "pages/Invoices";
import PaymentsPage from "pages/Payments";
import ProductsPage from "pages/Products";
import ExpensePage from "pages/Expenses";
import ExpenseCategoriesPage from "pages/ExpenseCategories";

import NewCustomerPage from "pages/Customers/newCustomer";
import NewRentalPage from "pages/Rentals/newRental";
import NewUnitPage from "pages/Units/newUnit";
import NewPaymentPage from "pages/Payments/newPayment";
import NewPaymentRental from "pages/Payments/tabs/newPaymentRental";
import NewPaymentDeposit from "pages/Payments/tabs/newPaymentDeposit";
import NewPaymentProduct from "pages/Payments/tabs/newPaymentProduct";
import NewProductPage from "pages/Products/newProduct";
import NewExpensePage from "pages/Expenses/newExpense";
import MapPage from "pages/Units/map";
import RentalInvoices from "pages/Rentals/rentalInvoices";
import UnitRentals from "pages/Units/unitRentals";
import EditUnitPage from "pages/Units/editUnit";
import EditProductPage from "pages/Products/editProduct";
import EditRentalPage from "pages/Rentals/editRental";
import NewInvoicePage from "pages/Invoices/newInvoices";
import EditInvoicePage from "pages/Invoices/editInvoice";
import EditExpensePage from "pages/Expenses/editExpense";

// Customer pages
import ShowCustomerPage from "pages/Customers/show";
import EditCustomerPage from "pages/Customers/editCustomer";
import InvoicePaymentPage from "pages/Payments/invoicePayment";

// Guest pages
import SignInPage from "pages/SignIn";
import PasswordRecovery from "pages/PasswordRecovery/passwordRecovery";
import EditPassword from "pages/PasswordRecovery/editPassword";

export const adminRoutes = [
  {
    name: "Usuarios",
    icon: <Icon>group</Icon>,
    route: "/users",
    component: <UsersPage />,
    showInNavbar: true,
  },
  {
    name: "Sucursales",
    icon: <Icon>business</Icon>,
    route: "/subsidiaries",
    component: <SubsidiariesPage />,
    showInNavbar: true,
  },
];

export const managerRoutes = [
  {
    name: "Inicio",
    icon: <Icon>dashboard</Icon>,
    route: "/dashboard",
    component: <DashboardPage />,
    showInNavbar: true,
  },
  {
    name: "Clientes",
    icon: <Icon>groups</Icon>,
    route: "/customers",
    component: <CustomersPage />,
    showInNavbar: true,
  },
  {
    name: "Unidades",
    icon: <Icon>business</Icon>,
    route: "/units",
    component: <UnitsPage />,
    showInNavbar: true,
  },
  {
    name: "Contratos",
    icon: <Icon>description</Icon>,
    route: "/rentals",
    component: <RentalsPage />,
    showInNavbar: true,
  },
  {
    name: "Ingresos",
    icon: <Icon>receipt_long</Icon>,
    showInNavbar: true,
    collapse: [
      {
        name: "Cobros",
        icon: <Icon>receipt_long</Icon>,
        route: "/invoices",
        component: <InvoicesPage />,
        showInNavbar: true,
      },
      {
        name: "Pagos",
        icon: <Icon>payment</Icon>,
        route: "/payments",
        component: <PaymentsPage />,
        showInNavbar: true,
      },
    ],
  },
  {
    name: "Egresos",
    icon: <Icon>payment</Icon>,
    showInNavbar: true,
    collapse: [
      {
        name: "Gastos",
        icon: <Icon>payment</Icon>,
        route: "/expenses",
        component: <ExpensePage />,
        showInNavbar: true,
      },
      {
        name: "Categorías",
        icon: <Icon>payment</Icon>,
        route: "/expenseCategories",
        component: <ExpenseCategoriesPage />,
        showInNavbar: true,
      },
    ],
  },
  {
    name: "Productos",
    icon: <Icon>store_mall_directory</Icon>,
    route: "/products",
    component: <ProductsPage />,
    showInNavbar: true,
  },
  {
    mobileName: "Editar Sucursal",
    icon: <Icon>settings</Icon>,
    route: "/subsidiaries/edit",
    component: <EditSubsidiaryPage />,
    showInNavbar: true,
  },
  {
    route: "/customers/new",
    component: <NewCustomerPage />,
    showInNavbar: false,
  },
  {
    route: "/rentals/new",
    component: <NewRentalPage />,
    showInNavbar: false,
  },
  {
    route: "/units/new",
    component: <NewUnitPage />,
    showInNavbar: false,
  },
  {
    route: "/products/new",
    component: <NewProductPage />,
    showInNavbar: false,
  },
  {
    route: "/units/map",
    component: <MapPage />,
    showInNavbar: false,
  },
  {
    route: "/rentals/:id/invoices",
    component: <RentalInvoices />,
    showInNavbar: false,
  },
  {
    route: "/units/:id/rentals",
    component: <UnitRentals />,
    showInNavbar: false,
  },
  {
    route: "/products/:id/edit",
    component: <EditProductPage />,
    showInNavbar: false,
  },
  {
    route: "/units/:id/edit",
    component: <EditUnitPage />,
    showInNavbar: false,
  },
  {
    route: "/rentals/:id/edit",
    component: <EditRentalPage />,
    showInNavbar: false,
  },
  {
    route: "/expenses/new",
    component: <NewExpensePage />,
    showInNavbar: false,
  },
  {
    route: "/expenses/:id/edit",
    component: <EditExpensePage />,
    showInNavbar: false,
  },
  {
    route: "/invoices/new",
    component: <NewInvoicePage />,
    showInNavbar: false,
  },
  {
    route: "/invoices/:id/edit",
    component: <EditInvoicePage />,
    showInNavbar: false,
  },
];

export const customerRoutes = [
  {
    route: "/payments/new",
    component: <NewPaymentPage />,
    showInNavbar: false,
  },
  {
    route: "/payments/rental/new",
    component: <NewPaymentRental />,
    showInNavbar: false,
  },
  {
    route: "/payments/deposit/new",
    component: <NewPaymentDeposit />,
    showInNavbar: false,
  },
  {
    route: "/payments/product/new",
    component: <NewPaymentProduct />,
    showInNavbar: false,
  },
  {
    route: "/customers/:id",
    component: <ShowCustomerPage />,
    showInNavbar: false,
  },
  {
    route: "/customers/:id/edit",
    component: <EditCustomerPage />,
    showInNavbar: false,
  },
  {
    route: "/payments/:id/create-invoice",
    component: <InvoicePaymentPage />,
    showInNavbar: false,
  },
  {
    route: "/payments/:id/new",
    component: <NewPaymentPage />,
    showInNavbar: false,
  },
];

export const guestRoutes = [
  {
    route: "/sign-in",
    component: <SignInPage />,
    showInNavbar: false,
  },
  {
    route: "/password-recovery",
    component: <PasswordRecovery />,
    showInNavbar: false,
  },
  {
    route: "/edit-password/:token",
    component: <EditPassword />,
    showInNavbar: false,
  },
];
