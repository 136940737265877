// React imports
import { useState } from "react";

// React Router imports
import { useNavigate, useParams } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material UI Components
import Card from "@mui/material/Card";

// Layouts
import AuthLayout from "layouts/authLayout";

// Custom Alert
import customAlert from "components/Alerts/CustomAlert";

// Custom Colors
import colors from "assets/theme/base/colors";

// Providers
import authProvider from "providers/authProvider";

function EditPassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, password_confirmation } = formData;

    if (!password || !password_confirmation) {
      customAlert({
        icon: "error",
        title: "Error",
        text: "Todos los campos son obligatorios.",
      });
      return;
    }

    if (password !== password_confirmation) {
      customAlert({
        icon: "error",
        title: "Error",
        text: "Las contraseñas no coinciden.",
      });
      return;
    }

    await authProvider.resetPassword({
      password,
      password_confirmation,
      token,
    });

    customAlert({
      icon: "success",
      title: "Éxito",
      text: "La contraseña se cambió correctamente.",
    }).then(() => {
      navigate("/sign-in");
    });
  };

  return (
    <AuthLayout>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info.main"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          pt={2.5}
          pb={2.875}
          px={2.5}
          textAlign="center"
          lineHeight={1}
        >
          <MKTypography
            variant="h4"
            fontWeight="medium"
            color={colors.white}
            mt={1}
          >
            Cambia tu contraseña
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox
            component="form"
            role="form"
            method="post"
            onSubmit={handleSubmit}
          >
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Nueva Contraseña"
                name="password"
                fullWidth
                value={formData.password}
                onChange={handleInputChange}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Confirmar Contraseña"
                name="password_confirmation"
                fullWidth
                value={formData.password_confirmation}
                onChange={handleInputChange}
              />
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton
                type="submit"
                variant="gradient"
                color="primary"
                fullWidth
              >
                Cambiar Contraseña
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </AuthLayout>
  );
}

export default EditPassword;
