// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useNavigate } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material UI Components
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import ReceiptIcon from "@mui/icons-material/Receipt";
import UpdateIcon from "@mui/icons-material/Update";
import DescriptionIcon from "@mui/icons-material/Description";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

// Custom Components
import ActionsMenu from "components/Menu";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// Providers
import rentalsProvider from "providers/rentalsProvider";

// Utilities
import {
  currencyFormatter,
  numericDateFormat,
  shortDateFormat,
  longDateFormat,
} from "utils";

const CONTRACT_TEMPLATE_ID = "7273123A-49A8-4043-9413-35E09E99EE39";

function RentalsTable({ fetchData, loading, page, setPage }) {
  // Context and hooks
  const navigate = useNavigate();

  // Derived states or memoized values
  const columns = useMemo(
    () => [
      { name: "unidad", align: "left" },
      { name: "cliente", align: "left" },
      { name: "precio mensual", align: "left" },
      { name: "fecha inicial", align: "left" },
      { name: "fecha final", align: "left" },
      { name: "estatus", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [generatingContract, setGeneratingContract] = useState(false);

  // General states
  const [contractDownloadUrl, setContractDownloadUrl] = useState(null);
  const [createdContractId, setCreatedContractId] = useState(null);
  const [state, setState] = useState({
    rentals: [],
    rows: [],
    total: 0,
    page: 0,
  });

  useEffect(() => {
    fetchData().then((data) => {
      setState((prevState) => ({
        ...prevState,
        rentals: data.rentals,
        total: data.total,
      }));
    });

    if (generatingContract && createdContractId) {
      const maxRetries = 10;
      let retries = 0;
      const interval = setInterval(async () => {
        const { data } = await rentalsProvider.getContract(createdContractId);
        retries++;
        if (data.document_card.status === "failed") {
          clearInterval(interval);
          setGeneratingContract(false);
        } else if (data.document_card.download_url) {
          clearInterval(interval);
          setContractDownloadUrl(data.document_card.download_url);
          setGeneratingContract(false);
        } else if (retries >= maxRetries) {
          clearInterval(interval);
          setGeneratingContract(false);
        }
      }, 2000);
    }

    if (contractDownloadUrl) {
      customAlert({
        title: "¡Contrato generado!",
        text: "¿Deseas descargar el contrato?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(contractDownloadUrl, "_blank");
        }
        setContractDownloadUrl(null);
      });
    }
  }, [fetchData, contractDownloadUrl, createdContractId, generatingContract]);

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback(
    (_ev, page) => {
      setPage(page);
    },
    [setPage]
  );

  // Deletes a rental and refreshes the rentals list
  const deleteRental = useCallback(
    async function (rentalId) {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer. Se perderá toda la información del contrato.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.deleteRental(rentalId);

            const data = await fetchData();
            setState((prevState) => ({
              ...prevState,
              rentals: data.rentals,
              total: data.total,
            }));

            customAlert({
              title: "Eliminado!",
              text: "El contrato ha sido eliminado.",
              icon: "success",
            });
            navigate("/rentals");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al eliminar el contrato.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  const cancelRental = useCallback(
    async function (rentalId) {
      customAlert({
        title: "¿Estás seguro?",
        text: "Escribe el motivo de cancelación del contrato.",
        input: "text",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Cancelar contrato",
        cancelButtonText: "Mantener contrato",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.updateRental(rentalId, {
              status: "cancelado",
              motive: result.value,
            });

            const data = await fetchData();
            setState((prevState) => ({
              ...prevState,
              rentals: data.rentals,
              total: data.total,
            }));

            customAlert({
              title: "Cancelado!",
              text: "El contrato ha sido cancelado exitosamente.",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al cancelar el contrato.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData]
  );

  const handleExtendContract = useCallback(
    (rentalId, currentEndDate) => {
      customAlert({
        title: "Extender contrato",
        text: "Selecciona la nueva fecha de finalización del contrato",
        input: "date",
        inputValue: currentEndDate,
        inputAttributes: {
          min: new Date(currentEndDate).toISOString().split("T")[0],
        },
        showCancelButton: true,
        confirmButtonText: "Extender",
        cancelButtonText: "Cancelar",
        inputValidator: (value) => {
          if (!value) {
            return "Debes seleccionar una nueva fecha";
          }
          if (new Date(value) <= new Date(currentEndDate)) {
            return "La nueva fecha debe ser mayor a la fecha de finalización actual";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.updateRental(rentalId, {
              end_date: result.value,
            });

            const data = await fetchData();
            setState((prevState) => ({
              ...prevState,
              rentals: data.rentals,
              total: data.total,
            }));

            customAlert({
              title: "Contrato extendido",
              html: `
              <p>La nueva fecha de finalización es:</p>
              <p style="font-weight: bold;">${longDateFormat(result.value)}</p>
            `,
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al extender el contrato.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData]
  );

  const handleRenewContract = useCallback(
    async (rentalId) => {
      try {
        const { data: rentalData } = await rentalsProvider.getRental(rentalId);

        if (rentalData.status === "atrasado") {
          const result = await customAlert({
            title: "Aviso importante",
            html: `
            <p>Este contrato tiene cobros atrasados.</p>
            <p>No se modificarán ni eliminarán, y seguirán registrándose como pendientes.</p> `,
            icon: "warning",
            confirmButtonText: "Continuar",
          });
          if (!result.isConfirmed) {
            return;
          }
        }

        navigate("/rentals/new", { state: { rentalData, isRenewing: true } });
      } catch (error) {
        console.error("Error al renovar el contrato:", error);
      }
    },
    [navigate]
  );

  const handleChangeUnit = useCallback(
    async (rentalId) => {
      try {
        const { data: rentalData } = await rentalsProvider.getRental(rentalId);

        if (rentalData.status === "atrasado") {
          const result = await customAlert({
            title: "Aviso importante",
            html: `
            <p>Este contrato tiene cobros atrasados.</p>
            <p>No se modificarán ni eliminarán, y seguirán registrándose como pendientes.</p> `,
            icon: "warning",
            confirmButtonText: "Continuar",
          });
          if (!result.isConfirmed) {
            return;
          }
        }

        const customerData = {
          user_id: rentalData.user.id,
          first_name: rentalData.user.first_name,
          last_name: rentalData.user.last_name,
          user_guarantee_name: rentalData.user_guarantee_name || "",
          stored_goods: rentalData.stored_goods || "",
          previous_rental: rentalId,
        };

        navigate("/rentals/new", {
          state: { customerData, isUnitChanged: true },
        });
      } catch (error) {
        console.error("Error al cambiar de unidad:", error);
      }
    },
    [navigate]
  );

  const generateContract = useCallback(async (rental) => {
    setGeneratingContract(true);
    let unitMeasurement = "________";
    if (rental.unit.width && rental.unit.length) {
      if (rental.unit.height) {
        unitMeasurement = `${rental.unit.width}m de ancho x ${rental.unit.length}m de largo x ${rental.unit.height}m de alto`;
      } else {
        unitMeasurement = `${rental.unit.width}m de ancho x ${rental.unit.length}m de largo`;
      }
    }

    const data = {
      document: {
        document_template_id: CONTRACT_TEMPLATE_ID,
        status: "pending",
        payload: {
          customer: {
            name:
              rental.user.fiscal_name || rental.user_fiscal_name || "________",
            fiscal_number:
              rental.user.fiscal_number ||
              rental.user_fiscal_number ||
              "________",
            address: {
              street: rental.user.street || rental.user_street || "________",
              zip_code:
                rental.user.zip_code || rental.user_zip_code || "________",
              city: rental.user.city || rental.user_city || "________",
              neighborhood:
                rental.user.neighborhood ||
                rental.user_neighborhood ||
                "________",
            },
          },
          owner: {
            name:
              rental.subsidiary.fiscal_name ||
              rental.subsidiary_fiscal_name ||
              "________",
            fiscal_number:
              rental.subsidiary.fiscal_number ||
              rental.subsidiary_fiscal_number ||
              "________",
            email:
              rental.subsidiary.email || rental.subsidiary_email || "________",
          },
          guarantee: {
            name: rental.user_guarantee_name || "________",
          },
          unit: {
            id: rental.unit.name || rental.unit_name || "________",
            measurement: unitMeasurement,
            address: {
              street:
                rental.subsidiary.street ||
                rental.subsidiary_street ||
                "________",
              zip_code:
                rental.subsidiary.zip_code ||
                rental.subsidiary_zip_code ||
                "________",
              city:
                rental.subsidiary.city || rental.subsidiary_city || "________",
              state:
                rental.subsidiary.state ||
                rental.subsidiary_state ||
                "________",
              neighborhood:
                rental.subsidiary.neighborhood ||
                rental.subsidiary_neighborhood ||
                "________",
            },
          },
          billing: {
            name:
              rental.subsidiary.billing_name ||
              rental.billing_name ||
              "________",
            bank:
              rental.subsidiary.billing_bank ||
              rental.billing_bank ||
              "________",
            account:
              rental.subsidiary.billing_account ||
              rental.billing_account ||
              "________",
            CLABE:
              rental.subsidiary.billing_clabe ||
              rental.billing_clabe ||
              "________",
          },
          rental: {
            folio: rental.id || "________",
            start_date: numericDateFormat(rental.start_date) || "________",
            stored_goods: rental.stored_goods || "________",
            duration_months: rental.duration_months || "________",
            duration_months_text: rental.duration_months_text || "________",
            monthly_rent: rental.monthly_rent || "________",
            monthly_rent_text: rental.monthly_rent_text || "________",
            deposit: rental.deposit || "________",
            deposit_text: rental.deposit_text || "________",
            start_day: new Date(rental.start_date).getUTCDate() || "________",
            start_month:
              new Date(rental.start_date).toLocaleDateString("es-MX", {
                timeZone: "UTC",
                month: "long",
              }) || "________",
          },
        },
        meta: {
          _filename: `Contrato-${rental.id}.pdf`,
        },
      },
    };

    rentalsProvider.generateContract(data).then(({ data }) => {
      const {
        document: { id },
      } = data;
      setCreatedContractId(id);
    });
  }, []);

  useEffect(() => {
    if (state.rentals && state.rentals.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.rentals.map((rental) => ({
          unidad: (
            <MKTypography variant="button" fontWeight="medium">
              {`${rental.unit.floor || ""}${rental.unit.block || ""} ${
                rental.unit.name
              }`}
            </MKTypography>
          ),
          cliente: (
            <MKButton
              variant="text"
              color="dark"
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
              component={Link}
              to={`/customers/${rental.user.id}#Contratos`}
            >
              {`${rental.user.first_name} ${rental.user.last_name}`}
            </MKButton>
          ),
          "precio mensual": (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(rental.monthly_rent)}
            </MKTypography>
          ),
          "fecha inicial": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(rental.start_date)}
            </MKTypography>
          ),
          "fecha final": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(rental.end_date)}
            </MKTypography>
          ),
          estatus: (
            <MKTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {rental.status}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  ...(rental.status !== "cancelado" &&
                  rental.status !== "terminado"
                    ? [
                        {
                          text: "Editar",
                          icon: <EditIcon />,
                          onClick: () => navigate(`/rentals/${rental.id}/edit`),
                        },
                      ]
                    : []),
                  {
                    text: "Ver Cobros",
                    icon: <ReceiptIcon />,
                    onClick: () => navigate(`/rentals/${rental.id}/invoices`),
                  },
                  {
                    text: "Generar Contrato",
                    icon: <DescriptionIcon />,
                    onClick: () => generateContract(rental),
                  },

                  ...(rental.status !== "cancelado" &&
                  rental.status !== "terminado"
                    ? [
                        {
                          text: "Extender Contrato",
                          icon: <UpdateIcon />,
                          onClick: () =>
                            handleExtendContract(rental.id, rental.end_date),
                        },
                      ]
                    : []),
                  ...(rental.status !== "cancelado"
                    ? [
                        {
                          text: "Renovar Contrato",
                          icon: <AutorenewIcon />,
                          onClick: () => handleRenewContract(rental.id),
                        },
                      ]
                    : []),
                  ...(rental.status !== "cancelado" &&
                  rental.status !== "terminado"
                    ? [
                        {
                          text: "Cambiar de Unidad",
                          icon: <SwapHorizIcon />,
                          onClick: () => handleChangeUnit(rental.id),
                        },
                      ]
                    : []),
                  ...(rental.status !== "cancelado" &&
                  rental.status !== "terminado"
                    ? [
                        {
                          text: "Cancelar",
                          icon: <CancelIcon />,
                          onClick: () => cancelRental(rental.id),
                          requiredRoles: [1, 2],
                        },
                      ]
                    : []),
                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () => deleteRental(rental.id),
                    requiredRoles: [1, 2],
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [
    state.rentals,
    deleteRental,
    navigate,
    generateContract,
    cancelRental,
    handleExtendContract,
    handleRenewContract,
    handleChangeUnit,
  ]);

  return (
    <Grid container item xs={12} lg={12}>
      {loading || generatingContract ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="15vh"
        >
          <VideoLoader />
        </Box>
      ) : state.rows.length === 0 ? (
        <MKTypography variant="h6" fontWeight="medium">
          No se encontraron contratos
        </MKTypography>
      ) : (
        <Table
          columns={columns}
          rows={state.rows}
          total={state.total}
          page={page}
          handlePageChange={handlePageChange}
        />
      )}
    </Grid>
  );
}
export default RentalsTable;
